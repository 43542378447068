import React from 'react';
import { Settings as CarouselSettings } from 'react-slick';

import * as Styled from '../../atoms/Carousel/styles';

export const settings: CarouselSettings = {
  dots: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 6000,
  speed: 1000,
  slidesToShow: 1,
  draggable: true,
  fade: true,
  appendDots: (dots: React.ReactNode) => (
    <Styled.CarouselPaginationDots>{dots}</Styled.CarouselPaginationDots>
  ),
  customPaging: () => <Styled.CarouselPagination />,
};
