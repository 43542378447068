import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import * as Styled from './styles';
import { AboutUsProps } from './types';
import { settings } from './const';

import Heading from '../../molecules/Heading';
import Image from '../../atoms/Image';
import Link from '../../atoms/Link';
import { LinkMode } from '../../atoms/Link/enums';

const AboutUs: React.FC<AboutUsProps> = ({ data }) => {
  const { subtitle, title, contentRichText, images, book, action } = data;

  const { label: bookLabel, link: bookUrl } = book || {};
  const { label: actionLabel, link: actionUrl } = action || {};

  return (
    <Styled.AboutUs>
      <Styled.AboutUsInner>
        <Styled.AboutUsContent>
          <Styled.AboutUsHeading>
            <Heading {...{ subtitle, title, contentRichText }} />
          </Styled.AboutUsHeading>
          {(bookUrl || actionUrl) && (
            <Styled.AboutUsActionBox>
              {bookUrl && (
                <Styled.AboutUsAction isMobileHidden>
                  <Link {...bookUrl}>{bookLabel}</Link>
                </Styled.AboutUsAction>
              )}
              {actionUrl && (
                <Styled.AboutUsAction>
                  <Link {...actionUrl} mode={LinkMode.HasUnderline} hasOverlayMask>
                    {actionLabel}
                  </Link>
                </Styled.AboutUsAction>
              )}
            </Styled.AboutUsActionBox>
          )}
        </Styled.AboutUsContent>
        <Styled.AboutUsMedia>
          <Slider {...settings}>
            {images.map(({ image, mobileImage, key }) => (
              <Styled.AboutUsSlide {...{ key }}>
                <Styled.AboutUsImage isMobileHidden={!!mobileImage}>
                  <Image {...image} />
                </Styled.AboutUsImage>
                {mobileImage && (
                  <Styled.AboutUsImageMobile>
                    <Image {...mobileImage} />
                  </Styled.AboutUsImageMobile>
                )}
              </Styled.AboutUsSlide>
            ))}
          </Slider>
        </Styled.AboutUsMedia>
      </Styled.AboutUsInner>
    </Styled.AboutUs>
  );
};

export default AboutUs;
