import { graphql } from 'gatsby';
import React from 'react';

import { getHref, getImage } from '../../../../utils/sanityTypes';
import { MediaItemProps } from '../../../atoms/Image/types';
import AboutUs from '../../AboutUs';
import { AboutUsSectionProps } from './types';

const AboutUsSection: React.VFC<AboutUsSectionProps> = ({
  subtitle,
  title,
  content,
  primaryButton,
  secondaryButton,
  images,
}) => {
  const { buttonLabel: primaryButtonLabel, link: primaryLink } = primaryButton ?? {};
  const { buttonLabel: secondaryButtonLabel, link: secondaryLink } = secondaryButton ?? {};

  return (
    <AboutUs
      data={{
        subtitle: subtitle ?? '',
        title: title ?? '',
        contentRichText: content ?? undefined,
        book: {
          label: primaryButtonLabel ?? '',
          link: { to: getHref(primaryLink) ?? '' },
        },
        action: {
          label: secondaryButtonLabel ?? '',
          link: { to: getHref(secondaryLink) ?? '' },
        },
        images: (images ?? []).map<MediaItemProps>((currentImage) => {
          const { _key: key, image, mobileImage } = currentImage ?? {};

          return {
            key: key ?? '',
            image: getImage(image),
            mobileImage: getImage(mobileImage),
          };
        }),
      }}
    />
  );
};

export const fragment = graphql`
  fragment AboutUsSectionFragment on SanityAboutUsSection {
    _key
    _type
    subtitle
    title
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    primaryButton {
      buttonLabel
      link {
        ...LinkFragment
      }
    }
    secondaryButton {
      buttonLabel
      link {
        ...LinkFragment
      }
    }
    images {
      _key
      image {
        ...ImageFragment
      }
      mobileImage {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default AboutUsSection;
