import styled, { css } from 'styled-components';

import { AboutUsMobileHiddenStyledProps } from './types';
import { Box, Inner, FlexBox } from '../../styles/Grid/styles';
import media from '../../../common/MediaQueries';
import { CarouselPaginationDots } from '../../atoms/Carousel/styles';

export const AboutUs = styled(Box)``;

export const AboutUsInner = styled(Inner)`
  display: flex;
  flex-direction: column;

  padding-top: 7.5rem;

  @media ${media.tablet} {
    padding-bottom: 7.5rem;
  }

  @media ${media.tablet} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const AboutUsContent = styled(FlexBox)`
  flex-direction: column;
  margin-bottom: 5rem;

  @media ${media.tablet} {
    justify-content: center;
    width: 57%;
    max-width: 25rem;
    margin-bottom: 0;
    padding-right: 2rem;
  }
`;

export const AboutUsHeading = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;

  @media ${media.phone} {
    margin-bottom: 5rem;
  }
`;

export const AboutUsActionBox = styled(FlexBox)`
  flex-direction: column;
  @media ${media.phone} {
    flex-direction: row;
    align-items: center;
  }
`;

export const AboutUsAction = styled.div<AboutUsMobileHiddenStyledProps>`
  position: relative;

  ${({ isMobileHidden }) =>
    isMobileHidden &&
    css`
      @media ${media.maxTablet} {
        display: none;
      }
    `}

  &:not(:first-child) {
    padding: 1rem 1.5rem 1rem 0;
    color: ${({ theme }) => theme.colors.secondary.hover};
  }

  @media ${media.tablet} {
    &:not(:first-child) {
      padding: 1rem 1.5rem;
      margin-left: 1rem;
    }
  }
`;

export const AboutUsMedia = styled.div`
  position: relative;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
    width: 100%;
  }

  ${CarouselPaginationDots} {
    justify-content: flex-start;
  }

  @media ${media.maxTablet} {
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    height: 33.75rem;
    max-height: calc(100vh - 5rem);

    ${CarouselPaginationDots} {
      position: absolute;
      bottom: 1.5rem;
      padding-left: 1.5rem;
    }
  }

  @media ${media.tablet} {
    width: 43%;
    height: 40rem;

    ${CarouselPaginationDots} {
      margin-top: 2rem;
    }
  }
`;

export const AboutUsSlide = styled.div`
  height: 100%;
  cursor: pointer;

  @media ${media.tablet} {
    border-radius: 0.25rem;
    overflow: hidden;
  }
`;

export const AboutUsImage = styled.div<AboutUsMobileHiddenStyledProps>`
  height: 100%;

  ${({ isMobileHidden }) =>
    isMobileHidden &&
    css`
      @media ${media.maxPhone} {
        display: none;
      }
    `}
`;

export const AboutUsImageMobile = styled.div`
  height: 100%;

  @media ${media.phone} {
    display: none;
  }
`;
